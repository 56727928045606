import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import * as t from "io-ts";
import { optionFromUndefined } from "../globalDomain";
import { sharedReducerConfig } from "../BranchExperience/useSharedReducer";

const UploadDocumentsState = t.type({
  id: t.literal("UploadDocuments"),
  biometricConsent: optionFromUndefined(t.boolean),
  activeIndex: optionFromUndefined(t.number),
});
type UploadDocumentsState = t.TypeOf<typeof UploadDocumentsState>;
const ConfirmDataState = t.type({
  id: t.literal("ConfirmData"),
  // none only when the biometricConsent feature is disabled
  biometricConsent: optionFromUndefined(t.boolean),
  activeIndex: optionFromUndefined(t.number),
});
type ConfirmDataState = t.TypeOf<typeof ConfirmDataState>;
const AdditionalDocumentsState = t.type({
  id: t.literal("AdditionalDocuments"),
  biometricConsent: optionFromUndefined(t.boolean),
  activeIndex: optionFromUndefined(t.number),
});
type AdditionalDocumentsState = t.TypeOf<typeof AdditionalDocumentsState>;
const PermanentAddressState = t.type({
  id: t.literal("PermanentAddress"),
  biometricConsent: optionFromUndefined(t.boolean),
  activeIndex: optionFromUndefined(t.number),
});
type PermanentAddressState = t.TypeOf<typeof PermanentAddressState>;
const SelfieCheckState = t.type({
  id: t.literal("SelfieCheck"),
  biometricConsent: optionFromUndefined(t.boolean),
  activeIndex: optionFromUndefined(t.number),
});
type SelfieCheckState = t.TypeOf<typeof SelfieCheckState>;
const ConfirmSelfieState = t.type({
  id: t.literal("ConfirmSelfie"),
  biometricConsent: optionFromUndefined(t.boolean),
  activeIndex: optionFromUndefined(t.number),
});
type ConfirmSelfieState = t.TypeOf<typeof ConfirmSelfieState>;
const AdditionalAddressesState = t.type({
  id: t.literal("AdditionalAddresses"),
  biometricConsent: optionFromUndefined(t.boolean),
  activeIndex: optionFromUndefined(t.number),
});
type AdditionalAddressesState = t.TypeOf<typeof AdditionalAddressesState>;
const CompletedState = t.type({
  id: t.literal("Completed"),
  biometricConsent: optionFromUndefined(t.boolean),
  activeIndex: optionFromUndefined(t.number),
});
type CompletedState = t.TypeOf<typeof CompletedState>;
const StateWithoutGdpr = t.union([
  UploadDocumentsState,
  ConfirmDataState,
  AdditionalDocumentsState,
  PermanentAddressState,
  AdditionalAddressesState,
  CompletedState,
  SelfieCheckState,
  ConfirmSelfieState,
]);
const StateGdpr = t.partial({ showGdprConsent: t.boolean });
export const State = t.intersection([StateWithoutGdpr, StateGdpr]);
export type State = t.TypeOf<typeof State>;

export function foldState<T>(
  state: State,
  match: {
    whenUploadDocuments: (state: UploadDocumentsState) => T;
    whenConfirmData: (state: ConfirmDataState) => T;
    whenAdditionalDocuments: (state: AdditionalDocumentsState) => T;
    whenPermanentAddress: (state: PermanentAddressState) => T;
    whenAdditionalAddresses: (state: AdditionalAddressesState) => T;
    whenCompleted: (state: CompletedState) => T;
    whenSelfieCheck: (state: SelfieCheckState) => T;
    whenConfirmSelfie: (state: ConfirmSelfieState) => T;
  }
) {
  switch (state.id) {
    case "UploadDocuments":
      return match.whenUploadDocuments(state);
    case "ConfirmData":
      return match.whenConfirmData(state);
    case "SelfieCheck":
      return match.whenSelfieCheck(state);
    case "ConfirmSelfie":
      return match.whenConfirmSelfie(state);
    case "AdditionalDocuments":
      return match.whenAdditionalDocuments(state);
    case "PermanentAddress":
      return match.whenPermanentAddress(state);
    case "AdditionalAddresses":
      return match.whenAdditionalAddresses(state);
    case "Completed":
      return match.whenCompleted(state);
  }
}

const ConfirmUploadedDocumentsAction = t.type({
  id: t.literal("ConfirmUploadedDocuments"),
  payload: t.type({
    hasSelfieCheckStep: t.boolean,
  }),
});
export function confirmUploadedDocumentsAction(
  hasSelfieCheckStep: boolean
): Action {
  return {
    id: "ConfirmUploadedDocuments",
    payload: { hasSelfieCheckStep },
  };
}

const ConfirmSelfieAction = t.type({
  id: t.literal("ConfirmSelfie"),
});
export const confirmSelfieAction: Action = {
  id: "ConfirmSelfie",
};

const SetConfirmDataStepAction = t.type({
  id: t.literal("SetConfirmDataStep"),
});

export const setConfirmDataStepAction: Action = {
  id: "SetConfirmDataStep",
};

const ConfirmDataAction = t.type({
  id: t.literal("ConfirmData"),
  payload: t.type({
    hasPermanentAddressStep: t.boolean,
    hasAdditionalAddressesStep: t.boolean,
    hasAdditionalDocumentsStep: t.boolean,
  }),
});
export function confirmDataAction(
  hasPermanentAddressStep: boolean,
  hasAdditionalAddressesStep: boolean,
  hasAdditionalDocumentsStep: boolean
): Action {
  return {
    id: "ConfirmData",
    payload: {
      hasPermanentAddressStep,
      hasAdditionalAddressesStep,
      hasAdditionalDocumentsStep,
    },
  };
}

const ConfirmAdditionalDocumentsAction = t.type({
  id: t.literal("ConfirmAdditionalDocuments"),
  payload: t.type({
    hasPermanentAddressStep: t.boolean,
    hasAdditionalAddressesStep: t.boolean,
  }),
});
export function confirmAdditionalDocumentsAction(
  hasPermanentAddressStep: boolean,
  hasAdditionalAddressesStep: boolean
): Action {
  return {
    id: "ConfirmAdditionalDocuments",
    payload: { hasPermanentAddressStep, hasAdditionalAddressesStep },
  };
}

const ConfirmPermanentAddressAction = t.type({
  id: t.literal("ConfirmPermanentAddress"),
  payload: t.type({
    hasAdditionalAddressesStep: t.boolean,
  }),
});
export function confirmPermanentAddressAction(
  hasAdditionalAddressesStep: boolean
): Action {
  return {
    id: "ConfirmPermanentAddress",
    payload: { hasAdditionalAddressesStep },
  };
}

const ConfirmAdditionalAddressesAction = t.type({
  id: t.literal("ConfirmAdditionalAddresses"),
});
export const confirmAdditionalAddressessAction: Action = {
  id: "ConfirmAdditionalAddresses",
};

const SetBiometricConsentAction = t.type({
  id: t.literal("SetBiometricConsent"),
  payload: t.boolean,
});
export function setBiometricConsentAction(biometricConsent: boolean): Action {
  return {
    id: "SetBiometricConsent",
    payload: biometricConsent,
  };
}
export type SetBiometricConsentAction = t.TypeOf<
  typeof SetBiometricConsentAction
>;
const SetShowGdprConsentAction = t.type({
  id: t.literal("SetShowGdprConsentAction"),
  payload: t.boolean,
});
export function setShowGdprConsentAction(show: boolean): Action {
  return {
    id: "SetShowGdprConsentAction",
    payload: show,
  };
}
const UploadAgainAction = t.type({
  id: t.literal("UploadAgain"),
});
export const uploadAgainAction: Action = { id: "UploadAgain" };

const RemoveUploadedDocumentsAction = t.type({
  id: t.literal("RemoveUploadedDocuments"),
});
export const removeUploadedDocumentsAction: Action = {
  id: "RemoveUploadedDocuments",
};

const SetActiveItemAction = t.type({
  id: t.literal("SetActiveItem"),
  payload: optionFromUndefined(t.number),
});
export function setActiveItemAction(activeItem: Option<number>): Action {
  return {
    id: "SetActiveItem",
    payload: activeItem,
  };
}

const Action = t.union([
  ConfirmUploadedDocumentsAction,
  ConfirmDataAction,
  ConfirmSelfieAction,
  ConfirmAdditionalDocumentsAction,
  ConfirmPermanentAddressAction,
  ConfirmAdditionalAddressesAction,
  SetBiometricConsentAction,
  RemoveUploadedDocumentsAction,
  UploadAgainAction,
  SetActiveItemAction,
  SetConfirmDataStepAction,
  SetShowGdprConsentAction,
]);
type Action = t.TypeOf<typeof Action>;

function reducer(state: State, action: Action): State {
  switch (action.id) {
    case "ConfirmUploadedDocuments":
      if (action.payload.hasSelfieCheckStep) {
        return {
          ...state,
          id: "SelfieCheck",
          activeIndex: pipe(
            state.activeIndex,
            option.map(i => i + 1)
          ),
        };
      }
      return {
        ...state,
        id: "ConfirmData",
        activeIndex: pipe(
          state.activeIndex,
          option.map(i => i + 1)
        ),
      };
    case "ConfirmSelfie":
      return {
        ...state,
        id: "ConfirmSelfie",
        activeIndex: pipe(
          state.activeIndex,
          option.map(i => i + 1)
        ),
      };
    case "ConfirmData":
      if (action.payload.hasAdditionalDocumentsStep) {
        return {
          ...state,
          id: "AdditionalDocuments",
          activeIndex: pipe(
            state.activeIndex,
            option.map(i => i + 1)
          ),
        };
      }
      if (action.payload.hasPermanentAddressStep) {
        return {
          ...state,
          id: "PermanentAddress",
          activeIndex: pipe(
            state.activeIndex,
            option.map(i => i + 1)
          ),
        };
      }
      if (action.payload.hasAdditionalAddressesStep) {
        return {
          ...state,
          id: "AdditionalAddresses",
          activeIndex: pipe(
            state.activeIndex,
            option.map(i => i + 1)
          ),
        };
      }
      return {
        ...state,
        id: "Completed",
        activeIndex: option.none,
      };
    case "ConfirmAdditionalDocuments":
      if (action.payload.hasPermanentAddressStep) {
        return {
          ...state,
          id: "PermanentAddress",
          activeIndex: pipe(
            state.activeIndex,
            option.map(i => i + 1)
          ),
        };
      }
      if (action.payload.hasAdditionalAddressesStep) {
        return {
          ...state,
          id: "AdditionalAddresses",
          activeIndex: pipe(
            state.activeIndex,
            option.map(i => i + 1)
          ),
        };
      }
      return {
        ...state,
        id: "Completed",
        activeIndex: option.none,
      };
    case "ConfirmPermanentAddress":
      if (action.payload.hasAdditionalAddressesStep) {
        return {
          ...state,
          id: "AdditionalAddresses",
          activeIndex: pipe(
            state.activeIndex,
            option.map(i => i + 1)
          ),
        };
      }
      return {
        ...state,
        id: "Completed",
        activeIndex: option.none,
      };
    case "ConfirmAdditionalAddresses":
      return {
        ...state,
        id: "Completed",
        activeIndex: option.none,
      };

    case "SetBiometricConsent":
      return { ...state, biometricConsent: option.some(action.payload) };
    case "SetShowGdprConsentAction":
      return { ...state, showGdprConsent: action.payload };
    case "RemoveUploadedDocuments":
    case "UploadAgain":
      return { ...state, id: "UploadDocuments", activeIndex: option.some(0) };
    case "SetActiveItem":
      return { ...state, activeIndex: action.payload };
    case "SetConfirmDataStep":
      return {
        ...state,
        id: "ConfirmData",
      };
  }
}

export const reducerConfig = sharedReducerConfig(
  "IdUpload",
  State,
  Action,
  reducer
);
